<template>
  <div class="fs-14">
    <div
      v-if="contractData.type === 'address' || !contractData.type"
      class="sidebar__section px-3 stat"
    >
      <p class="sidebar__analytics-label pp-address">
        Address / Ens name
      </p>
      <div
        class="flex align-center fullwidth"
        :title="selectedElement.value"
      >
        <span class="m-word-break-all"> {{ selectedElement.data.id }} / {{ contractData.ens || '--' }} </span>
        <gl-menu-item
          class="sidebar__history-copy"
          icon="copy"
          :icon-height="24"
          :icon-width="24"
          @click="copy(selectedElement.data.id)"
        />
        <gl-menu-item
          v-if="isAdmin"
          v-popover:tooltip.top="'Edit labels'"
          class="pb-1"
          icon="edit-block-active"
          :icon-height="24"
          :icon-width="24"
          @click="toLabeling(selectedElement.data.id)"
        />
      </div>
    </div>
    <div
      v-else-if="contractData && contractData.type === 'tokenContract'"
      class="stat px-3 fullwidth"
    >
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Address / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.address || '--' }} / {{ contractData.ens || '--' }}
          <gl-menu-item
            class="sidebar__history-copy"
            icon="copy"
            :icon-height="24"
            :icon-width="24"
            @click="copy(contractData.address)"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-popover:tooltip.top="'Edit labels'"
            class="pb-1"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(contractData.address)"
          />
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Deployer / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractDeployer && contractData.contractDeployer.address || '--' }} / {{ contractData.contractDeployer && contractData.contractDeployer.ens || '--' }}
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Owner / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractOwner && contractData.contractOwner.address || '--' }} / {{ contractData.contractOwner && contractData.contractOwner.ens || '--' }}
        </div>
      </div>
    </div>
    <div
      v-else-if="contractData && contractData.type === 'contract'"
      class="stat px-3 fullwidth"
    >
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Address / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractAddress && contractData.contractAddress.address || contractData.address || '--' }} / {{ contractData.contractAddress && contractData.contractAddress.ens || '--' }}
          <gl-menu-item
            class="sidebar__history-copy"
            icon="copy"
            :icon-height="24"
            :icon-width="24"
            @click="copy(contractData.contractAddress && contractData.contractAddress.address || contractData.address)"
          />
          <gl-menu-item
            v-if="isAdmin"
            v-popover:tooltip.top="'Edit labels'"
            class="pb-1"
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            @click="toLabeling(contractData.contractAddress && contractData.contractAddress.address || contractData.address)"
          />
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Deployer / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractDeployer && contractData.contractDeployer.address || '--' }} / {{ contractData.contractDeployer && contractData.contractDeployer.ens || '--' }}
        </div>
      </div>
      <div class="mb-4 fullwidth">
        <div class="sidebar__analytics-label">
          Contract Owner / Ens name
        </div>
        <div class="flex align-center fullwidth">
          {{ contractData.contractOwner && contractData.contractOwner.address || '--' }} / {{ contractData.contractOwner && contractData.contractOwner.ens || '--' }}
        </div>
      </div>
    </div>
    <div class="stat px-3">
      <div class="stat-tag full mb-1">
        <InfoBlock
          :class="{'full': !selectedElement.data.nodeData.name}"
          font-size="14px"
          label="Owner"
          :link-text-value="Boolean(addressData && addressData.entityId)"
          :loading="isAddressDataLoading"
          :value="addressData.owner"
          @clickedValue="goToEntity(addressData && addressData.entityId)"
        />
        <InfoBlock
          v-if="selectedElement.data.nodeData.hasSymbol && contractData && contractData.type === 'tokenContract'"
          font-size="14px"
          label="Token"
          :loading="isAddressDataLoading"
          :value="selectedElement.data.nodeData.name"
        >
          <template #icon>
            <GlImg
              v-if="addressData.contractInfo && addressData.contractInfo.icon"
              :src-val="addressData.contractInfo && addressData.contractInfo.icon"
            />
          </template>
        </InfoBlock>
      </div>
      <div class="sidebar__section">
        <p class="sidebar__analytics-label">
          Tags
        </p>
        <VueSkeletonLoader
          v-if="isAddressDataLoading"
          animation="wave"
          :color="'#bec3d8'"
          :height="20"
          :radius="'2'"
          type="rect"
        />
        <div
          v-else-if="addressData && addressData.scoringList && addressData.scoringList.length > 0"
        >
          <GlTag
            v-for="(tag, index) in addressData.scoringList"
            :key="index"
            class="mr-1 mb-1"
            :class="{'hidden': index > 6}"
            :score="tag.score"
            :tag="tag.name"
          />
          <div
            v-if="addressData.scoringList.length > 6"
            class="all-tags-btn"
            @click="changeTab('tags')"
          >
            All tags
          </div>
        </div>
        <div
          v-else
          class="pp-tags-empty-block"
        >
          --
        </div>
        <template v-if="!isContract">
          <p
            v-if="featureAccess('ALERTS')"
            class="sidebar__analytics-label mt-3 pp-profile-flag"
          >
            Profile Flag
          </p>
          <div
            v-if="featureAccess('ALERTS')"
          >
            <GlFlagsWrap
              :flags-data="flagsData"
              hide-score
              :loading="isAddressDataLoading"
              :max-count="5"
            />
          </div>
          <div v-else-if="featureAccess('ALERTS')">
            --
          </div>
        </template>
      </div>
    </div>
    <!--    <div class="stat px-3">-->
    <!--      <InfoBlock-->
    <!--        label="Number Of Txs"-->
    <!--        :loading="isAddressDataLoading"-->
    <!--        :value="addressData.totalTxs"-->
    <!--      />-->
    <!--      <InfoBlock-->
    <!--        label="Total Send"-->
    <!--        :loading="isAddressDataLoading"-->
    <!--        :rate="formatByPrice(addressData.totalSendPrice)"-->
    <!--        :value="Number.isInteger(addressData.totalSend) && formatBtcAmount(addressData.totalSend, true, 'eth', false, true)"-->
    <!--      />-->
    <!--      <InfoBlock-->
    <!--        label="Total Received"-->
    <!--        :loading="isAddressDataLoading"-->
    <!--        :rate="formatByPrice(addressData.totalReceivePrice)"-->
    <!--        :value="Number.isInteger(addressData.totalReceive) && formatBtcAmount(addressData.totalReceive, true, 'eth', false, true)"-->
    <!--      />-->
    <!--    </div>-->
    <!--    <div class="stat px-3">-->
    <!--      <InfoBlock-->
    <!--        class="mb-4"-->
    <!--        label="First seen"-->
    <!--        :loading="isAddressDataLoading"-->
    <!--        :value="addressData-->
    <!--          && addressData.seen-->
    <!--          && formatDate(addressData.seen.first * 1000, 'dd.MM.yyyy HH:mm')"-->
    <!--      />-->
    <!--      <InfoBlock-->
    <!--        class="mb-4"-->
    <!--        label="Last seen"-->
    <!--        :loading="isAddressDataLoading"-->
    <!--        :value="addressData-->
    <!--          && addressData.seen-->
    <!--          && formatDate(addressData.seen.last * 1000, 'dd.MM.yyyy HH:mm')"-->
    <!--      />-->
    <!--    </div>-->
    <div v-if="isContractWithLabels">
      <div class="analytics-tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
        >
          <div
            v-if="tab.visible"
            class="analytics-tabs-item analytics-tabs-item--full"
            :class="[{'analytics-tabs-item--active': activeTab === tab.name}, {
              'hatching': (isContract && tab.name === 'sof')
                || (isTokenContract && tab.name === 'tx')
                || (isTokenContract && tab.name === 'sof')
                || !addressData || addressData && addressData.scoringList && !addressData.scoringList.length
                && addressData.meta && !addressData.meta.length
                && addressData.tags && !addressData.tags.length
                && tab.name === 'meta'
            }]"
            @click="changeTab(tab.name)"
          >
            {{ tab.title }}
          </div>
        </div>
      </div>
      <div
        v-if="activeTab === 'tx'"
        class="stat px-3"
      >
        <div class="stat-tag full">
          <div class="flex full mt-3">
            <div class="column full mr-2 flex-2">
              <div class="flex space-between align-flex-end">
                <div class="sidebar__analytics-label">
                  Filter by date & time
                </div>
              </div>
              <date-picker
                ref="datePicker"
                v-model="transactionsListDates"
                class="mb-2"
                :disabled="loading"
                :disabled-date="disabledRange"
                format="DD.MM.YYYY HH:mm:ss"
                placeholder="Select date & time range"
                range
                :show-time-panel="showTimeRangePanel"
                type="datetime"
                @clear="handleDateClose"
                @close="handleRangeClose"
              >
                <template v-slot:footer>
                  <div class="flex space-between">
                    <div>
                      <button
                        class="mx-btn mx-btn-text link"
                        @click="toggleTimeRangePanel"
                      >
                        {{ showTimeRangePanel ? 'Select date' : 'Select time' }}
                      </button>
                    </div>
                    <div>
                      <button
                        class="mx-btn mx-btn-text cancel-date-range mr-2"
                        @click="handleDateClose"
                      >
                        Cancel
                      </button>
                      <button
                        class="mx-btn mx-btn-text apply-date-range"
                        @click="setDate(transactionsListDates)"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </template>
              </date-picker>
            </div>
            <div class="relative flex-1">
              <div class="sidebar__analytics-label">
                Filter by amount
              </div>
              <div
                class="mr-1 ml-1 pointer filters-control flex space-between"
                @click="toggleFilter"
              >
                <div>
                  AMOUNT
                </div>
                <div
                  class="relative"
                  :class="{ 'filter-filled-sign': showFilerSign }"
                >
                  <gl-icon
                    class="pt-1"
                    :height="22"
                    name="filter"
                    :width="22"
                  />
                </div>
              </div>
              <ValidationObserver
                ref="txsListFilter"
                slim
              >
                <div
                  v-show="showFilter"
                  class="txs-list-filter-wrapper"
                >
                  <div class="flex space-between pa-3">
                    <div class="bold-600">
                      Filters
                    </div>
                    <div
                      class="flex align-center pointer soft-blue-text bold"
                      @click="clearFilters"
                    >
                      Clear filters
                    </div>
                  </div>
                  <div class="grey-f-5-hr" />

                  <div class="pa-3">
                    <div class="bold fs-14 mb-3">
                      Amount
                    </div>
                    <div class="flex gap-4">
                      <gl-input
                        v-model="filters.amount.min"
                        class="flex-1"
                        :height="40"
                        label="Min"
                        :max="filters.amount.max"
                        name="min"
                        placeholder="Min"
                        type="number"
                      />
                      <gl-input
                        v-model="filters.amount.max"
                        class="flex-1"
                        :height="40"
                        label="Max"
                        :min="filters.amount.min"
                        name="max"
                        placeholder="Max"
                        type="number"
                      />
                    </div>
                  </div>

                  <div class="grey-f-5-hr" />
                  <div class="flex flex-end pa-3">
                    <div
                      class="pointer soft-blue-text bold"
                      @click="applyFilterAmount"
                    >
                      Apply
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
          <div class="flex space-between fullwidth mt-3 filters-tab-wrapper m-white-space-nowrap">
            <div
              v-for="(item, index) in transactionsListFilters"
              :key="index"
              class="filters-tab"
              :class="[{'filters-tab__active': selectedFilter === item.type}, {'filters-tab__disabled': checkTxsCount(item.totalKey, contractData)}]"
              @click="changeFilterType(item)"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="full mt-3">
            <div class="stat-tag full m-flex m-gap-3">
              <div
                v-if="selectedFilter === 'tokens'"
                class="column mr-4"
              >
                <div class="flex space-between mb-2 align-center">
                  <div class="fs-12 mb-1 grey-text-6-e uppercase bold">
                    filter by token
                  </div>
                </div>
                <vSelect
                  v-model="token"
                  class="tokens-list mr-1"
                  :clearable="false"
                  :disabled="getTokensLoading"
                  label="symbol"
                  :options="tokensList"
                  placeholder="Select Token"
                  @input="handleSelectToken"
                >
                  <template v-slot:selected-option="{ symbol, icon }">
                    <GlImg
                      v-if="icon"
                      class="mr-1"
                      :src-val="icon"
                    />
                    {{ symbol }}
                  </template>
                  <template v-slot:option="option">
                    <GlImg
                      v-if="option.icon"
                      class="mr-1"
                      :src-val="option.icon"
                    />
                    {{ option.symbol }}
                  </template>
                </vSelect>
              </div>
              <InfoBlock
                class="mr-2"
                label="balance"
                :loading="loadingBalance"
                :rate="formatByPrice(price)"
                rate-time="current"
                :rate-timestamp="priceTimestamp"
                :value="formattedBalance"
              />
              <InfoBlock
                label="Number of txs"
                :loading="loadingMetrics"
                :value="toComaSeparate(String(txsAddPlus(total, 2000002)))"
              />
            </div>
          </div>
          <o-table
            ref="transactionActions"
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="pagination-table inputs-outputs-table table__overflow-auto"
            :data="localData"
            detail-transition="fade"
            detailed
            detailed-class="eth-tx-actions-detailed"
            hoverable
            :loading="loading || loadingDetails || loadingTxs"
            :mobile-cards="false"
            @check="handleCheck"
            @details-close="closeDetails"
            @details-open="getDetails"
          >
            <template
              slot="detail"
              slot-scope="props"
            >
              <div class="eth-tx-actions-detailed">
                <div
                  v-if="detailData.transfers && detailData.transfers.length"
                  class="sidebar__analytics-label mt-3"
                >
                  Executed transfers
                </div>
                <div
                  v-for="(transfer, index) in detailData.transfers"
                  :key="index"
                  class="flex white-space-nowrap mb-2"
                >
                  <div
                    v-if="transfer.type === 'transfer'"
                    class="fullwidth"
                  >
                    Transfer {{ transfer.amountText }}
                    <span
                      v-if="transfer.amountCur"
                      v-popover:tooltip.top="`${priceMessage('historical', transfer.amountCurTimestamp)}`"
                      class="price fs-12 ml-1 mr-1"
                    >
                      ({{ formatByPrice(transfer.amountCur) }})
                    </span>  from <span
                      class="link-text ml-1 mr-1"
                      @click="openInNewTabAddress(transfer.from.address)"
                    >{{ transfer.from.owner ? transfer.from.owner : trancateString(transfer.from.address, 9) }}</span> to <span
                      class="link-text ml-1 mr-3"
                      @click="openInNewTabAddress(transfer.to.address)"
                    >{{ transfer.to.owner ? transfer.to.owner : trancateString(transfer.to.address, 9) }}</span>
                  </div>
                  <div
                    v-if="transfer.type === 'method'"
                    class="fullwidth flex white-space-nowrap"
                  >
                    {{ transfer.method }} {{ transfer.amountText }}
                    <span
                      v-if="transfer.amountCur"
                      v-popover:tooltip.top="`${priceMessage('historical', transfer.amountCurTimestamp)}`"
                      class="price fs-12 ml-1 mr-1"
                    >
                      ({{ formatByPrice(transfer.amountCur) }})
                    </span> from <span
                      class="link ml-1 mr-1"
                      @click="openInNewTabAddress(transfer.from.address)"
                    >{{ transfer.from.owner ? transfer.from.owner : trancateString(transfer.from.address, 9) }}</span> to <span
                      class="link ml-1"
                      @click="openInNewTabAddress(transfer.to.address)"
                    >{{ transfer.to.owner ? transfer.to.owner : trancateString(transfer.to.address, 9) }}</span>
                  </div>
                  <div
                    v-if="transfer.type === 'unknown'"
                    class="fullwidth flex white-space-nowrap"
                  >
                    <span
                      class="link ml-1 mr-1"
                      @click="externalReference(`https://api.etherface.io/v1/signatures/hash/all/${transfer.topic}/1`)"
                    >{{ transfer.topic }}</span> {{ transfer.amountText }}
                    {{ transfer.method }} {{ transfer.amountText }}
                    <span
                      v-if="transfer.amountCur"
                      v-popover:tooltip.top="`${priceMessage('historical', transfer.amountCurTimestamp)}`"
                      class="price fs-12 ml-1 mr-1"
                    >
                      ({{ formatByPrice(transfer.amountCur) }})
                    </span> from <span
                      class="link ml-1 mr-1"
                      @click="openInNewTabAddress(transfer.from.address)"
                    >{{ transfer.from.owner ? transfer.from.owner : trancateString(transfer.from.address, 9) }}</span> to <span
                      class="link ml-1"
                      @click="openInNewTabAddress(transfer.to.address)"
                    >{{ transfer.to.owner ? transfer.to.owner : trancateString(transfer.to.address, 9) }}</span>
                  </div>
                </div>
                <div class="sidebar__analytics-label mt-3">
                  Transaction fee
                </div>
                <div>
                  {{ detailData.fee && detailData.fee.amountText || '--' }}
                  <span
                    v-if="detailData.fee && detailData.fee.price"
                    class="price fs-12"
                  >
                    ({{ formatByPrice(detailData.fee.price) }})
                    <gl-icon
                      v-popover:tooltip.top="`${priceMessage('historical', detailData.fee.priceTimestamp)}`"
                      class="price-info-icon"
                      :height="11"
                      name="info"
                      :width="11"
                    />
                  </span>
                </div>
              </div>
            </template>
            <template
              slot="empty"
              slot-scope="props"
            >
              <div
                v-if="!loading"
                class="flex justify-center full grey-text mt-3 mb-4"
              >
                Data is empty
              </div>
            </template>
            <o-table-column
              v-slot="props"
              field="date"
              label="Date"
              width="100"
            >
              <div class="min-w-80 m-white-space-nowrap">
                {{ formatDate(props.row.timestamp * 1000, 'dd.MM.yyyy HH:mm:ss') || '-' }}
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              field="txHash"
              label="transaction"
            >
              <div
                class="link pa-1"
                :class="{'text-highlight': props.row.hash === hoveredHash}"
                @click="openInNewTab(props.row.hash)"
                @mouseleave="hoveredHash=''"
                @mouseover="hoveredHash = props.row.hash"
              >
                {{ trancateString(props.row.hash, 12) }}
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              field="actions"
              label=""
            >
              <div class="link">
                <AddressTxActions
                  :tx="props.row.hash"
                />
              </div>
            </o-table-column>
            <o-table-column
              v-slot="props"
              label="AMOUNT"
              width="80"
            >
              <div
                class="flex column align-end white-space-nowrap"
                :class="[
                  {'squander': props.row.type && props.row.type[0] !== 'input'},
                  {'profit': props.row.type && props.row.type[0] === 'input'},
                ]"
              >
                <span>
                  {{ props.row.formattedAmount }}
                </span>
                <span
                  v-if="props.row.price"
                  class="fw-400 ml-1"
                > ({{ formatByPrice(props.row.price) }})
                  <gl-icon
                    v-popover:tooltip.top="`${priceMessage('historical', props.row.priceTimestamp)}`"
                    class="price-info-icon"
                    :height="11"
                    name="info"
                    :success="props.row.type && props.row.type[0] === 'input'"
                    :warn="props.row.type && props.row.type[0] !== 'input'"
                    :width="11"
                  />
                </span>
              </div>
            </o-table-column>
          </o-table>
          <div
            v-if="localData.length > 0"
            class="flex flex-end fullwidth pa-1"
          >
            <o-pagination
              v-if="localData.length"
              class="transactions-list-pagination"
              :current.sync="currentPage"
              order="centered"
              :per-page="perPage"
              size="small"
              :total="totalPages * perPage"
              @change="pageChange"
            >
              <o-pagination-button
                slot="previous"
                slot-scope="props"
                :page="props.page"
              >
                <gl-menu-item
                  class="change-page mr-2"
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>

              <o-pagination-button
                slot="next"
                slot-scope="props"
                :page="props.page"
              >
                <gl-menu-item
                  class="change-page"
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </o-pagination>
          </div>
        </div>
      </div>
      <div
        v-if="activeTab === 'meta'"
        class="pa-3"
      >
        <AddressTagsTable
          :address="selectedElement.data.id"
          :data="addressData"
        />
      </div>
      <div
        v-if="activeTab === 'sof'"
        class="relative"
      >
        <div v-if="calcLoading">
          <gl-loader class="table__loader" />
        </div>
        <div v-else>
          <div class="px-3 mt-3 flex space-between align-flex-end">
            <div class="column">
              <div class="flex space-between mb-2 align-center">
                <div class="fs-12 mb-1 grey-text-6-e uppercase bold">
                  filter by token
                </div>
              </div>
              <vSelect
                v-model="token"
                class="tokens-list mr-1"
                :clearable="false"
                :disabled="getTokensLoading"
                label="symbol"
                :options="tokensList"
                placeholder="Select Token"
                @input="handleSelectToken"
              >
                <template v-slot:selected-option="{ symbol, icon }">
                  <GlImg
                    v-if="icon"
                    class="mr-1"
                    :src-val="icon"
                  />
                  {{ symbol }}
                </template>
                <template v-slot:option="option">
                  <GlImg
                    v-if="option.icon"
                    class="mr-1"
                    :src-val="option.icon"
                  />
                  {{ option.symbol }}
                </template>
              </vSelect>
            </div>
            <div
              v-if="appConfig.VUE_APP_SCORE_CHART_DONUT"
              class="flex fs-14 align-end mr-3"
            >
              Amount
              <div class="px-1 switch__wrap">
                <input
                  id="switch"
                  v-model="trackActivator"
                  type="checkbox"
                  @click="trackBy()"
                >
                <label for="switch">Toggle</label>
              </div>
              %
            </div>
          </div>
          <div
            v-if="!uofData.incomingData.typeGrouping.length && !uofData.outgoingData.typeGrouping.length"
            class="empty-users-data text-center"
          >
            Data is empty
          </div>
          <div
            v-if="uofData.incomingData.typeGrouping.length || uofData.outgoingData.typeGrouping.length"
            class="px-3"
          >
            <div
              v-if="appConfig.VUE_APP_SCORE_CHART_DONUT && uofData.incomingData.typeGrouping.length"
              class="flex align-center justify-center relative"
            >
              <gl-pie-chart
                v-if="!isMobile && !calcLoading"
                ref="pie"
                :center="['50%', '60%']"
                class="relative aml-detected-list"
                :data-source="uofData.incomingData.typeGrouping"
                :height="470"
                :width="'100%'"
                @mouseout="mouseoutPieHover"
                @move="handlePieHover"
              >
                <template slot="tooltip">
                  <transition name="fade">
                    <div
                      v-if="sectionData"
                      id="sourcePie"
                      class="testPie"
                    >
                      <div
                        v-for="(owner, index) in sectionData.owners"
                        :key="index"
                        :class="{'mb-2': index < sectionData.owners.length - 1}"
                      >
                        <div class="flex align-center bold">
                          <div class="sub-types-amount">
                            <span class="">
                              {{ trackByField === 'share'
                                ? formatShare(allDataSource.length === 1 && sectionData.owners.length === 1 ? 1 : owner[trackByField])
                                : owner.formattedAmount }}
                            </span>
                          </div>
                          <div
                            v-popover:tooltip.top="`${capitalizeFirstLetter(owner.name)}`"
                            class="ml-3 ellipsis sub-types-owner max-w-300"
                          >
                            {{ capitalizeFirstLetter(owner.name) }}
                          </div>
                        </div>
                        <div
                          v-if="trackByField === 'amount'"
                          class="price fs-13"
                        >
                          {{ formatByPrice(owner['amountCur']) }}
                        </div>
                      </div>
                    </div>
                  </transition>
                </template>
              </gl-pie-chart>
              <PieDataList
                v-if="isMobile"
                class="m-fullwidth px-3"
                :data="uofData.incomingData.typeGrouping"
                :legend="null"
                title=""
                track-by-label="funds.type"
                track-by-label-support="funds.name"
                :track-value-by-field="trackByField"
              />
              <div class="risk-score-wrap">
                <strong class="risk-score-label">
                  Risk Score
                </strong>
                <div
                  class="risk-score-value"
                  :style="`color: ${findColorByTypeScore(totalFunds)}`"
                >
                  {{ formatFunds(totalFunds, false) }}
                </div>
              </div>
            </div>
            <template
              v-else-if="(uofData.incomingData.typeGrouping.length || uofData.outgoingData.typeGrouping.length) 
                && !appConfig.VUE_APP_SCORE_CHART_DONUT"
            >
              <div class="flex flex-wrap space-between mt-3">
                <GlSelectButton
                  :class="[{'mb-1': isMobile}]"
                  :disabled="calcLoading"
                  :options="partBarOptions"
                  :value="activePartBar"
                  @input="changeActivePartBarValue"
                />
                <GlSelectButton
                  v-if="Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2"
                  :class="[{'mb-1': isMobile}]"
                  :disabled="calcLoading"
                  :options="exposurePartOptions"
                  :value="activeExposurePart"
                  @input="changeActiveExposure"
                />
              </div>
              <div>
                <div class="pt-2">
                  <div class="chart-wrap__side-panel">
                    <gl-scoring-bar-chart
                      :active-part-bar="activePartBar"
                      :basis-percent-graph-width="30"
                      class="transparent-space-right"
                      :data-table="activeBarData"
                      full
                      :loading="calcLoading"
                      :max-share="uofData[`${activeExposurePart.value}Data`].maxShare"
                    />
                  </div>
                </div>
              </div>
            </template>
            <div
              v-else
              class="empty-users-data text-center"
            >
              Data is empty
            </div>
          </div>
        </div>
        <div class="source__action-block mt-2">
          <div
            v-if="addressIsOwnerByHightRisk
              || hasDirectlyMixing
              || Number(addressRiskPercent) > 0
              || addressAreUnidentified
              || hasTagMoreRiskPoint"
            class="mb-2"
          >
            <div class="flex align-center">
              <p class="sidebar__analytics-label__simple mr-2">
                AML RISK DETECTED
              </p>
              <gl-menu-item
                icon="risk-mark"
                :icon-height="16"
                :icon-width="16"
                :label="showRiskMsg ? 'Hide' : 'Show'"
                @click="showRiskMsg = !showRiskMsg"
              />
            </div>
            <div v-if="showRiskMsg">
              <StatusBlock
                v-if="addressIsOwnerByHightRisk"
                class="mb-1"
                label="Address is owned by a high risk entity"
              />
              <StatusBlock
                v-if="hasDirectlyMixing"
                class="mb-1"
                label="Address has directly participated in mixing"
              />
              <StatusBlock
                v-if="Number(addressRiskPercent) > 0"
                class="mb-1"
                :label="addressRiskPercentLabel"
              />
              <StatusBlock
                v-if="addressAreUnidentified"
                class="mb-1"
                label="More than 75% of sources for the address are unidentified"
              />
              <StatusBlock
                v-if="hasTagMoreRiskPoint"
                class="mb-3"
                label="Address has directly participated in high risk activities"
              />
            </div>
          </div>
          <div class="gl-form__actions">
            <button
              v-if="!addressData.isMonitoring"
              class="gl-button gl-button--light full-submit gl-form__button gl-button--padder mr-4"
              :disabled="calcLoading"
              @click="addToMonitoringList(addressData.address || selectedElement.data.id)"
            >
              Add to Monitoring
            </button>
            <button
              class="gl-button gl-button--dark full-submit gl-form__button gl-button--padder"
              @click="toFullReport(selectedElement.data.id)"
            >
              get full report
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapState} from 'vuex'
// Utils
import { formatDate, toSecondsStart, toSecondsEnd } from "@/utils/format-date";
import { toComaSeparate, toDecimalVal } from '@/utils/formatNumber'
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { capitalizeFirstLetter, trancateString, spamMessagesCombine } from "@/utils/text-formatter";
import { findColorByTypeScore, getNativeCoinIcon, tokensSorting } from "@/utils/cytoskape-ui-rules";
import { formatFunds, calcSourcesRiskPercent } from "@/utils/report-data-formatter";
import { featureAccess, isExceptionStatus } from "@/utils/accesses";
import { formatByPrice, priceMessage } from "@/utils/format-by-price";
import { goToEntity } from "@/utils/go-to-route";
import { txsAddPlus } from '@/utils/txs-add-plus'
import { formatterAmountValue } from '@/utils/report-data-formatter'
import { formatShare, formatter } from "@/utils/sourcesFormatter";
import { sortingObjects, sourcesSortOrderArray } from "@/utils/sorting";
// Components
import GlImg from "@/components/gl-img.vue";
import vSelect from "vue-select";
import GlTag from '@/components/gl-tag';
import GlIcon from "@/components/gl-icon";
import GlLoader from '@/components/gl-loader';
import InfoBlock from '@/components/gl-info-block';
import GlMenuItem from '@/components/gl-menu-item';
import VueSkeletonLoader from 'skeleton-loader-vue';
import AddressTagsTable from "@/pages/analytics/analytics-info-blocks/components/addressTagsTable";
import GlFlagsWrap from '@/components/gl-flags-wrap';
import AddressTxActions from './components/addressTxActions';
import { ValidationObserver } from 'vee-validate'
import GlInput from '@/components/gl-input.vue'
import GlSelect from '@/components/gl-select.vue'
import uofModel from '@/pages/analytics/analytics-info-blocks/models/uofModel'
import GlSelectButton from '@/components/gl-select-button.vue'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { AlertsMixin } from '@/assets/mixins/alertsMixin'
// Libs
import _ from "lodash";
import moment from "moment";
import StatusBlock from '@/pages/report/components/StatusBlock.vue'
import appConfig from '@/utils/appConfig'
import PieDataList from '@/pages/report/components/PieDataList.vue'
import GlPieChart from '@/components/charts/gl-pie-chart.vue'

export default {
  name: 'TransactionInfo',
  components: {
    GlPieChart,
    PieDataList,
    GlScoringBarChart,
    GlSelectButton,
    StatusBlock,
    ValidationObserver,
    GlSelect,
    GlInput,
    GlImg,
    GlTag,
    GlIcon,
    vSelect,
    GlLoader,
    InfoBlock,
    GlMenuItem,
    AddressTagsTable,
    VueSkeletonLoader,
    GlFlagsWrap,
    AddressTxActions,
  },
  mixins: [deviceWidthMixin, AlertsMixin],
  props: {
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      selectedFilter: 'main',
      calcLoading: false,
      totalFunds: 0,
      addressRiskPercent: 0,
      addressUnknownSources: [],
      sectionData: null,
      monitoringCalculateHandler: null,
      localData: [],
      allDataSource: [],
      tokensList: [],
      checkedRows: [],
      outputsCheckedRows: [],
      transactionsListDates: [],
      addressData: {},
      transactionData: {},
      contractData: {},
      getTokensLoading: false,
      loading: false,
      forward: false,
      backward: false,
      loadingTxs: false,
      loadingTxsCount: 0,
      loadingDetails: false,
      showTimePanel: false,
      loadingMetrics: false,
      loadingBalance: false,
      showTimeRangePanel: false,
      isAddressDataLoading: false,
      showRiskMsg: false,
      token: { address: '', symbol: 'ETH' },
      tabs: [{
        name: 'tx',
        title: 'Transactions',
        visible: true
      },{
        name: 'sof',
        title: 'Exposure',
        visible: featureAccess('REPORT')
      }, {
        name: 'meta',
        title: 'Tags & Metadata',
        visible: true
      }],
      activeTab: 'tx',
      defaultTab: 'tx',
      trackByField: 'share',
      trackActivator: 'share',
      balance: null,
      formattedBalance: null,
      price: null,
      priceTimestamp: null,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      limit: 10,
      detailData: {},
      flagsData: [],
      hoveredHash: '',
      hoveredPieDataKey: null,
      showFilter: false,
      filters: {
        amount: {
          min: null,
          max: null,
        },
      },
      uofData: uofModel(),
      activeExposurePart: { name: 'Source of Funds', value: 'incoming' },
      exposurePartOptions: [
        { name: 'Source of Funds', value: 'incoming' },
        { name: 'Use of Funds', value: 'outgoing' },
      ],
      activePartBar: { name: 'Type', value: 'funds.type', key: 'type' },
      partBarOptions: [
        { name: 'Type', value: 'funds.type', key: 'type' },
        { name: 'Entity', value: 'owner', key: 'owner' },
      ],
      step: 10
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    ...mapGetters('user', ['isAdmin']),
    activeBarData() {
      return this.uofData[`${this.activeExposurePart.value}Data`][`${this.activePartBar.key}Grouping`];
    },
    showFilerSign() {
      return Boolean(this.filters.amount.min || this.filters.amount.max)
    },
    isContract() {
      return this.selectedElement?.data?.nodeData?.isContract
    },
    isTokenContract() {
      return this.selectedElement?.data?.nodeData?.hasSymbol
    },
    hasLabels() {
      return this.selectedElement?.data?.nodeData?.riskScore !== -1
    },
    isContractWithLabels() {
      return this.selectedElement?.data?.nodeData?.hasSymbol
        ? this.selectedElement?.data?.nodeData?.hasSymbol && this.selectedElement?.data?.nodeData?.riskScore !== -1
        : true
    },
    transactionsListFilters() {
      const { type } = this.$route.query
      const filterLabel = type ? this.currencyList.find(el => el.key === type).label : this.coinData.label

      return [
        {
          label: `${filterLabel} Transactions`,
          type: 'main',
          totalKey: 'totalETHTxs',
        },
        {
          label: 'Internal TXs',
          type: 'internal',
          totalKey: 'totalInternalTxs',
        },
        {
          label: 'Token Transfers',
          type: 'tokens',
          totalKey: 'totalTokensTxs',
        },
      ]
    },
    addressIsOwnerByHightRisk() {
      return (this.addressData?.tags.find(tag => tag.score >= appConfig.VUE_APP_RISK_SCORE_TRESHOLD))
        || this.addressData?.type?.score >= appConfig.VUE_APP_RISK_SCORE_TRESHOLD
    },
    hasDirectlyMixing() {
      return (this.addressData?.type?.name === 'mixing')
        || Boolean(this.addressData?.tags.find(tag => tag.name === 'coin join participant'))
    },
    hasTagMoreRiskPoint() {
      return Boolean(this.addressData?.tags.find(tag => tag.score >= appConfig.VUE_APP_RISK_SCORE_TRESHOLD))
    },
    addressAreUnidentified() {
      const sum = this.addressUnknownSources && this.addressUnknownSources.reduce((acc, { share }) => acc + share, 0)

      return sum * 100 >= 75
    },
    addressRiskPercentLabel() {
      if (this.activeExposurePart.value === 'outgoing') {
        return `Address sent ${formatShare(this.addressRiskPercent)} funds to risky destinations`
      }
      return `Address received ${formatShare(this.addressRiskPercent)} funds from risky sources`
    },
  },
  watch: {
    'selectedElement.value': 'updateData',
  },
  async mounted() {
    if (!this.token.address) {
      this.token.symbol = this.coinData.label
      this.token.icon = getNativeCoinIcon(this.coinData.key)
    }
    await this.loadMetrics()

    if (this.isContract && this.hasLabels) {
      await this.changeTab('meta');
    } else {
      await this.changeTab(this.defaultTab);
    }

    await this.updateData()
  },
  methods: {
    calcSourcesRiskPercent,
    featureAccess,
    formatterAmountValue,
    tokensSorting,
    toDecimalVal,
    formatDate,
    formatShare,
    trancateString,
    findColorByTypeScore,
    formatFunds,
    toComaSeparate,
    formatBtcAmount,
    capitalizeFirstLetter,
    formatByPrice,
    priceMessage,
    goToEntity,
    toSecondsStart,
    toSecondsEnd,
    txsAddPlus,
    spamMessagesCombine,
    moveToTabByAddressType() {
      const dataForFilterTab = this.transactionsListFilters.find(filter => {
        return !this.checkTxsCount(filter.totalKey, this.contractData)
      })

      dataForFilterTab && this.changeFilterType(dataForFilterTab)
    },
    async toggleFilter() {
      this.showFilter = !this.showFilter
    },
    checkTxsCount(totalKey, contractData) {
        // eslint-disable-next-line no-prototype-builtins
      return contractData.hasOwnProperty(totalKey) && contractData[totalKey] === 0
    },
    changeFilterType({ type, totalKey }) {
      if (this.checkTxsCount(totalKey, this.contractData)) return;
      this.loading = true

      this.currentPage = 1

      if (type === 'tokens') {
          this.getTokensLoading = true
          this.getAddressTokens({ address: this.selectedElement.data.id, supported: false }).then(({ data }) => {
              // eslint-disable-next-line no-prototype-builtins
              this.tokensList = this.tokensSorting(data).filter(t => t.address)

              if (this.tokensList.length) {
                  this.token = this.tokensList[0]
              }

              this.loadAddressData(true, type)
          }).finally(() => {
              this.getTokensLoading = false
              this.loading = false
          })

          return
      }

      else {
          this.token = {}
          this.token.address = ''
          this.token.symbol = this.coinData.label
          this.loadAddressData(true, type)
      }
      this.$nextTick(() => {
        document.querySelector('.filters-tab-wrapper').scrollLeft = document.querySelector('.filters-tab__active').offsetLeft - 15
      });
    },
    addToMonitoringList(address) {
        this.calcLoading = true
        this.addToMonitoring({
            address,
            checkLastTen: false,
            blockchain: this.coinData.label
        }).then(({ success, data }) => {
            if (success) {
                this.$toasted.global.success({message: `Address added to monitoring`})
                this.getAddressesMonitoring({ skip: 0, count: 1, address})
                    .then(() => {
                        this.monitoringCalculateHandler = setTimeout(() => {
                            this.monitoringGettingInterval(address)
                        }, 3000);
                    })
                this.addressData.isMonitoring = true
            }
            else {
              this.calcLoading = false
              if (!isExceptionStatus(data.status)) {
                this.$toasted.global.error({message: `${data.message}`})
              }
            }
        })
    },
    monitoringGettingInterval(address) {
      this.monitoringCalculateHandler = setTimeout(() => {
        this.getAddressesMonitoring({ skip: 0, count: 1, address})
          .then(({ data }) => {
            if (data.list.length) {
                this.calcLoading = false
                clearTimeout(this.monitoringCalculateHandler);
                this.loadCalcData(false)
            } else {
                this.monitoringGettingInterval(address)
            }
          })
        }, 3000)
    },
    getDetails(item) {
        this.$refs.transactionActions.visibleDetailRows.forEach(row => {
            if (item.hash !== row.hash) {
                this.$refs.transactionActions.closeDetailRow(row)
            }
        })
        this.loadingDetails = true
        this.detailData = {}
        this.getTransactionActions({ tx: item.hash }).then(({ data, success }) => {
            if (!success) {
                this.$toasted.global.error({message: `${data.message}`})
            }
            this.detailData = data
            this.loadingDetails = false
        }).finally(() => {
            this.loadingDetails = false
        })
    },
    calkDataFormatter({ sources = [], currencies = {}, totalFunds = null }, direction, partOptions) {
      this.uofData[`${direction}Data`].sources = sources
      this.uofData[`${direction}Data`].currencies = currencies
      this.uofData[`${direction}Data`][`${partOptions.key}Grouping`] = sortingObjects(formatter(sources, partOptions.value), sourcesSortOrderArray)
        .map((source) => {
          const localCurrency = currencies[source.currency]
            ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
            : {}

          return {
            ...source,
            owners: formatter(sources, 'owner')
              .filter((v) => v.funds.type === source.funds.type)
              .map((v) => ({
                ...v,
                name: v.owner,
                formattedAmount: this.formatBtcAmount(v.amount / Math.pow(10, localCurrency.decimals ?? this.coinData.decimals ?? 18), true,  this.coinData.key, localCurrency.currency || this.coinData.label),
              })),
            funds: {
              ...source.funds,
              default: source.funds.default || false
            },
            key: source.funds.type,
            formattedAmount: this.formatBtcAmount(source.amount / Math.pow(10, localCurrency.decimals ?? this.coinData.decimals ?? 18), true,  this.coinData.key, localCurrency.currency || this.coinData.label),
            name: `${this.trackByField === 'share'
              ? formatShare(source.share)
              : this.formatBtcAmount(source.amount / Math.pow(10, localCurrency.decimals ?? this.coinData.decimals ?? 18), true,  this.coinData.key, localCurrency.currency || this.coinData.label)} ${source.funds.type}`,
            tooltip: `${source.funds.type} ${formatShare(source.share)}`,
            pieValue: source.share,
            value: source.share,
            itemStyle: {color: source.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(source.funds.score)},
            currencyData: localCurrency,
          }
        })

      this.uofData[`${direction}Data`][`${partOptions.key}Grouping`].map((item) => {
        item.owners = formatter(item.owners, 'name') || []
      })

      const opIndex = this.exposurePartOptions.findIndex(({ value }) => value === direction)

      if (opIndex !== -1) {
        this.$set(this.exposurePartOptions[opIndex], 'disabled', Boolean(!sources.length))
      }

      const maxValue = this.uofData[`${direction}Data`][`${partOptions.key}Grouping`].reduce(
        (max, obj) => (obj.share > max ? obj.share : max),
        this.uofData[`${direction}Data`][`${partOptions.key}Grouping`][0]?.share,
      )

      this.uofData[`${direction}Data`].maxShare = Math.ceil((maxValue * 100) / this.step) * this.step

      if (direction === this.activeExposurePart.value) {
        this.addressUnknownSources = this.uofData[`${direction}Data`].sources.filter(source => source.listType === 'Unknown sources')

        this.addressRiskPercent = 0

        this.addressRiskPercent = this.calcSourcesRiskPercent(this.uofData[`${direction}Data`][`${partOptions.key}Grouping`])

        if (this.appConfig.VUE_APP_SCORE_CHART_DONUT) {
          this.totalFunds = totalFunds
        }
      }
    },
    closeDetails() {
      // Temp solution
      // this.detailData = {}
    },
    toFullReport(val) {
      const { href } = this.$router.resolve({ name: 'report', query: { address: val, type: this.coinType, token: this.token.address || ''}})
      window.open(href, '_blank')
    },
    externalReference(link) {
        const href = `${link}`
        window.open(href, '_blank')
    },
    openInNewTabAddress(address) {
      if (!address) {
          this.$toasted.global.error({message: `Empty data`})
          return
      }
      const { href } = this.$router.resolve({ name: 'analytics', query: { address, type: this.coinType } })
      window.open(href, '_blank')
    },
    loadMetrics() {
      this.loadingMetrics = true

      const sendData = {
        address: this.selectedElement.data.id,
        contract: this.token ? this.token.address : undefined,
        startRangeDate: this.transactionsListDates.length ? this.startRangeDate : null,
        endRangeDate: this.transactionsListDates.length ? this.endRangeDate : null,
        type: this.selectedFilter
      }

      this.getAddressMetrics(sendData).then(({ data: { totalTxs } }) => {
        this.total = totalTxs.total
      }).finally(() => {
        this.loadingMetrics = false
      })
    },
    changeActivePartBarValue(val) {
      this.activePartBar = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    changeActiveExposure(val) {
      this.activeExposurePart = val
      this.calkDataFormatter(
        this.uofData[`${this.activeExposurePart.value}Data`],
        this.activeExposurePart.value,
        this.activePartBar
      )
    },
    async updateData() {
      this.filters = {
        amount: {
          min: null,
          max: null,
        },
      }
      this.uofData = uofModel()
      if (this.isContract && this.hasLabels) {
        await this.changeTab('meta');
      } else {
        await this.changeTab(this.defaultTab);
      }

      this.currentPage = 1
      this.token = { address: '', symbol: this.coinData.label }

      if (this.featureAccess('ALERTS')) {
        this.loadAlerts(this.selectedElement.data.id).then((data) => {
          this.flagsData = data || []
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.flagsData = []
      }

      await this.loadAddressBalance();

      switch (this.activeTab) {
        case 'tx':
          await this.loadAddressData();
          break;
      }

      this.moveToTabByAddressType()
    },
    ...mapActions('analytics', [
      'getTxEthData',
      'getAddressData',
      'getAddressTokens',
      'getAddressBalance',
      'getAddressMetrics',
      'getEthAddressInfo',
      'getEthAddressData',
      'getAddressEthScore',
      'getTxEthScore',
      'getTransactionActions',
      'getAddressUOF',
    ]),
    ...mapActions('monitoring', ['addToMonitoring', 'getAddressesMonitoring']),
    ...mapActions('entity', ['getEntityList']),
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    disabledRange(date) {
      return date > moment().endOf('day');
    },
    formattingScoringList(data) {
      let SCORING_LIST = []

      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }

      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, data.type]
      }

      SCORING_LIST = SCORING_LIST.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)

      SCORING_LIST.sort((a, b) => ((a.score < b.score)) ? 1 : -1)

      return SCORING_LIST
    },
    toLabeling(val) {
      const { href } = this.$router.resolve({ name: 'labeling', query: { address: val, tab: 'labeling', type: this.coinType }})

      window.open(href, '_blank')
    },
    setDate(date) {
      const [from, to] = date

      if (from, to) {
        this.startRangeDate = this.toSecondsStart(from);
        this.endRangeDate = this.toSecondsEnd(to);
        this.transactionsListDates = [new Date(this.startRangeDate * 1000), new Date(this.endRangeDate * 1000)]
        this.currentPage = 1
        this.$refs.datePicker.closePopup()
        this.loadAddressBalance()
        this.loadAddressData(true, this.selectedFilter);
      } else this.clearFilters()
    },
    async applyFilterAmount() {
      const isValid = await this.$refs.txsListFilter.validate()
      if (!isValid) return
      this.showFilter = false
      this.loadAddressData(true, this.selectedFilter);
    },
    getTxDirection(type) {
      if ((type.find(el => el === 'input') && type.find(el => el === 'output')) || type.find(el => el === 'swap')) return 'input-output-arrow'
      if (type.find(el => el === 'input')) return 'input-arrow'
      if (type.find(el => el === 'output')) return 'output-arrow'
      return null
    },
    changeTab(tab) {
      if (!this.addressData?.scoringList?.length
          && !this.addressData?.meta?.length
          && !this.addressData?.tags?.length
          && !this.hasLabels
          && tab === 'meta') return

      if (this.isContract && (tab === 'sof')) return;
      if (this.isTokenContract && (tab === 'sof' || tab === 'tx')) return;

      this.token = {
        address: '',
        symbol: this.coinData.label,
        icon: getNativeCoinIcon(this.coinData.key)
      }

      this.activeTab = tab
      switch (tab) {
        case 'tx':
          this.loadAddressData();
          break;
        case 'sof':
          this.loadCalcData();
          break;
        case 'general':
          this.loadAddressBalance();
          break;
      }
      this.$nextTick(() => {
        document.querySelector('.analytics-tabs').scrollLeft = document.querySelector('.analytics-tabs-item--active').offsetLeft - 10
      });
    },
    trackBy() {
      if (this.trackByField === 'share') {
        this.trackByField = 'amount'
      } else {
        this.trackByField = 'share'
      }

      this.uofData.incomingData.typeGrouping = this.uofData.incomingData.typeGrouping.map(item => ({
        ...item,
        name: `${this.trackByField === 'share' ? formatShare(item.share) : item.formattedAmount} ${item.funds.type}`,
        tooltip: `${item.funds.type} ${this.trackByField === 'share' ? formatShare(item.share) : formatBtcAmount(this.toDecimalVal(item.amount, item.decimals), true, 'eth', item.swapData ? item.swapData.to.symbol : item.symbol)}`,
      }))
    },
    handlePieHover($event) {
      const a = document.getElementById('sourcePie')

      if (a) {
        a.style.left = `${$event.event.event.clientX + 10}px`
        a.style.top = `${$event.event.event.clientY + 10}px`
      }

      if (!this.hoveredPieDataKey && $event.event.event.clientX) {
        this.sectionData = $event.data || null
      }
      this.hoveredPieDataKey = $event?.data?.key || null
    },
    mouseoutPieHover() {
      this.sectionData = null
      this.hoveredPieDataKey = null
    },
    loadCalcData(isNeedLoadTokens = true) {
      this.calcLoading = true
      this.totalFunds = null

      if (isNeedLoadTokens) {
        this.getTokensLoading = true
        this.getAddressTokens({ address: this.selectedElement.data.id }).then(({ data }) => {
          // data.unshift({ address: '', symbol: this.coinData.label, icon: getNativeCoinIcon(this.coinData.key) })
          // eslint-disable-next-line no-prototype-builtins
          this.tokensList = this.tokensSorting(data)
          const tokenIndex = this.tokensList.findIndex(item => item.address === this.token.address)

          if (tokenIndex === -1) {
            this.token = this.tokensList[0]
          } else {
            this.token = this.tokensList[tokenIndex]
          }
        }).catch(({ response: { data } }) => {
          if (data?.data?.message) {
            this.$toasted.global.error({message: `${data.data.message}`})
          }

          this.calcLoading = false
        }).finally(() => {
          this.getTokensLoading = false
        })
      }

      this.getAddressUOF({ address: this.selectedElement.data.id,token: this.token.address, tokenTicker: this.token.symbol }).then(({ data, success }) => {
        if (!success && data.message) {
          this.$toasted.global.error({ message: data.message })
        }

        const { sof, uof }  = data

        this.showResultMessage(sof)
        this.showResultMessage(uof)

        this.calkDataFormatter(sof?.data || {}, 'incoming', this.activePartBar)
        this.calkDataFormatter(uof?.data || {}, 'outgoing', this.activePartBar)

        const sofTotalFunds = sof?.data?.totalFunds ?? null;
        const uofTotalFunds = uof?.data?.totalFunds ?? null;

        this.totalFunds = (sofTotalFunds === null && uofTotalFunds === null)
          ? null
          : (!appConfig.VUE_APP_MIN_MAX_TOTAL_FUNDS ? sofTotalFunds : Math.max(sofTotalFunds, uofTotalFunds));

        const availableExposurePart = this.exposurePartOptions.find(part => !part.disabled);

        if (this.activeExposurePart.value !== availableExposurePart.value) {
          this.changeActiveExposure(availableExposurePart);
        }

        this.calcLoading = false;
      }).catch(() => {
        this.calcLoading = false
      }).finally(() => {
        const availableExposurePart = this.exposurePartOptions.find(part => !part.disabled)

        if (this.activeExposurePart.value !== availableExposurePart.value) {
          this.changeActiveExposure(availableExposurePart)
        }
        this.calcLoading = false
      })
    },
    showResultMessage(direction) {
      const dataRes = direction?.data
      if (dataRes?.meta) {
        this.$toasted.global.info({ message: this.spamMessagesCombine({ meta: dataRes?.meta }) })
      }
    },
    combineSourcesOwners() {
      this.allDataSource.map((item) => {
        item.owners = formatter(item.owners, 'name')
      })
    },
    pageChange(page) {
      this.currentPage = page
      this.loadAddressData(true, this.selectedFilter)
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.$refs.datePicker.closePopup()
      this.loadAddressData(true, this.selectedFilter);
    },
    clearFilters() {
      this.showFilter = false
      this.filters = {
        amount: {
          min: null,
          max: null
        }
      }
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadAddressData(true, this.selectedFilter);
    },
    openInNewTab(tx) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    handleSelectToken() {
      if (this.activeTab === 'sof') {
        this.activeExposurePart = this.exposurePartOptions[0]
        this.loadCalcData(false)
      } else {
        this.currentPage = 1
        this.loadAddressBalance();
        this.loadAddressData(true, this.selectedFilter);
      }
    },
    async loadEthData(tx, page, limit) {
      this.loading = true

      const { success: gettingCallsStatus, data: { txs: callsTxs, total: callsTotal } } = await this.getTxEthData(
        {
          tx,
          limit: this.limit,
          page,
          only: 'calls'
        })

      this.getTxEthData({
        tx,
        page,
        limit,
        address: this.selectedElement.data.id,
        only: 'list'
      }).then(({data, success}) => {
        if (!success) {
          this.$toasted.global.error({message: `${data.message}`})
          this.loading = false
          return
        }

        if (gettingCallsStatus && Array.isArray(callsTxs)) {
          data.txs = data.txs.concat(callsTxs)
        }

        data.txs = data.txs.map(item => ({
          ...item,
          totalTxs: data.total + callsTotal || 0,
          prevTx: item.prevTx || [],
          nextTx: item.nextTx || []
        }))

        this.$emit('set-eth-transaction', {
          search: tx,
          data
        })
      }).catch(({response: {data}}) => {
        this.$toasted.global.error({message: `${data.message}`})
      }).finally(() => {
        this.loading = false
      })
    },
    async handleCheck(val, row) {
      // const selectedEl = val[val.length - 1]
      const diffItem = _.difference(this.checkedRows, val)
      const isAdd = val.length > this.checkedRows.length

      if (isAdd) {
        this.loadEthData(row.hash, 1, 10)
      } else {
        if (diffItem.length > 0) {
          diffItem.forEach(ele => {
            this.cytoscape.searchAndHideEthElementsByTxHash(ele.hash)
            this.checkedRows = this.checkedRows.filter(({ hash }) => hash !== ele.hash)
          })
        } else {
          this.cytoscape.searchAndHideEthElementsByTxHash(row.hash)
          this.checkedRows = this.checkedRows.filter(({ hash }) => hash !== row.hash)
        }
        this.loadAddressData(true, this.selectedFilter)
        // await this.checkingRows()
      }
    },
    checkingRows() {
      this.localData.map((item) => {
        if (this.cytoscape.searchElements(item.hash, false, false).length > 0) {
          this.checkedRows.push(item)
        }
      })
      this.preCheckedRows = this.checkedRows;
    },
    async loadAddressBalance(withLoader = true) {
      if (withLoader) {
        this.loadingBalance = true
      }

      this.isAddressDataLoading = true

      await this.getEthAddressInfo(this.selectedElement.data.id)
          .then(({ data, success }) => {
            if (success) {
              this.addressData = {
                ...data,
                tags: data.tags && data.tags.length > 0 ? data.tags : [],
                scoringList: this.formattingScoringList(data) || []
              };

              this.contractData = {
                ...data,
                type: data.addressType,
              }
              this.gettingEntityByOwner(data?.owner)
            } else {
              this.addressData = {}
            }
            this.isAddressDataLoading = false
          }).finally(() => {
        this.loadingBalance = false
        this.isAddressDataLoading = false
      })

      await this.getAddressBalance({
        address: this.selectedElement.data.id,
        contract: this.token ? this.token.address : undefined })
        .then(({ success, data }) => {
          if (success) {
            this.balance = Number(data.balance.amount) || 0
            this.price = Number(data.balance.price) || 0
            this.priceTimestamp = Number(data.balance.priceTimestamp) || null
          } else {
            this.balance = 0
            this.price = 0
            this.priceTimestamp = null
          }
          this.loadingBalance = false
        })
    },
    loadAddressData(withLoader = true, type = 'main') {
      this.selectedFilter = type
      if (withLoader) {
        this.loadingTxs = true
        this.loading = true
      }
      this.loadingTxsCount++

      this.getEthAddressData({
        address: this.selectedElement.data.id,
        startRangeDate: this.transactionsListDates.length ? this.startRangeDate : null,
        endRangeDate: this.transactionsListDates.length ? this.endRangeDate : null,
        contract: this.token && type === 'tokens'? this.token.address : undefined,
        page: this.currentPage,
        minAmount: this.filters.amount.min || 0,
        maxAmount: this.filters.amount.max || undefined,
        only: 'list',
        type
      })
          .then(({ data: { list, forward, backward, pages }, success }) => {
              if (success) {
                  this.localData = list.map(el => {
                    return {
                      ...el,
                      formattedAmount: toComaSeparate(this.formatBtcAmount(el.amount / Math.pow(10, this.token.decimals ?? this.coinData.decimals ?? 18), true,  this.coinData.key, this.token.symbol || this.coinData.label))
                    }
                  })
                  this.totalPages = pages
                  this.backward = backward
                  this.forward = forward
              } else {
                  this.localData = []
                  this.totalPages = 0
                  this.backward = false
                  this.forward = false
              }
          }).finally(() => {
            this.loadingTxsCount--
            if (this.loadingTxsCount <= 0) {
              this.loadingTxs = false
            }
            this.loading = false
            this.checkingRows()
      })

      this.getAddressBalance({
          address: this.selectedElement.data.id,
          contract: this.token ? this.token.address : undefined })
          .then(({ success, data }) => {
            if (success) {
              this.balance = Number(data.balance.amount) || 0
              this.price = Number(data.balance.price) || 0
              this.priceTimestamp = Number(data.balance.priceTimestamp) || null
              this.formattedBalance = this.balance === 0 ? '0' : toComaSeparate(String(formatBtcAmount(this.balance, true, this.coinData.family, this.token.symbol || this.coinData.label)))
            } else {
              this.balance = 0
              this.price = 0
              this.priceTimestamp = null
            }
            this.loadingBalance = false
          }).catch(() => {
          this.loadingBalance = false
      })

      this.loadMetrics()
    },
    async copy(value) {
      await navigator.clipboard.writeText(value)
        .then(() => {
          this.$toasted.global.success({ message: 'Copied!' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    gettingEntityByOwner(owner) {
      if (owner) {
        this.getEntityList({ name: owner }).then((data) => {
          if (data?.items?.find(el => el?.name?.value.toLowerCase() === owner.toLowerCase())) {
            const entity = data?.items?.find(el => el?.name?.value === owner).id || null
            this.$nextTick(() => {
              this.$set(this.addressData, 'entityId', entity)
            })
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.sidebar-container {
  flex: 1;
  overflow-y: auto;
}
.sidebar-title {
  margin-bottom: 25px;
}
.stat {
  display: flex;
  flex-wrap: wrap;
}
.stat-item {
  width: 25%;
  word-break: break-word;
  margin-bottom: 20px;
}
.full {
  width: 100%;
}
.key {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  margin-bottom: 6px;
}
.stat-tag {
  display: flex;
  flex-wrap: wrap;
}

.analytics-tabs {
  justify-content: space-around;
}

.hatching {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 49.9%, var(--dark-grey-d-3) 49.9%, var(--dark-grey-d-3) 60%, rgba(0, 0, 0, 0) 80%) fixed, linear-gradient(-45deg, var(--dark-grey-d-3) 16%, rgba(0, 0, 0, 0) 25%) fixed;
  background-size: 0.5em 0.5em;
  opacity: 0.6;
}

.table__loader {
  position: absolute;
  top: calc(50% + 40px);
  left: 50%;

  transform: translate(-50%, -50%);
}

.risk-score-value {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.risk-score-wrap {
  position: absolute;
  top: 255px;
}

.risk-score-label {
  font-size: 14px;
}

.testPie {
  /*min-width: 400px;*/
  width: max-content;
  position: fixed;
  padding: 16px;
  z-index: 10;
  background-color: var(--white);
  box-shadow: 4px 5px 8px 0 rgba(211, 211, 211, 0.64);
  border-radius: 3px;
  /*border: solid 1px var(--soft-blue);*/
  transform: translateX(-100%);
}

.o-table__detail {
    background: var(--pale-grey) !important;
    box-shadow: inset 0 1px 3px var(--pale-grey) !important;
}

.eth-tx-actions-detailed {
    padding-left: 40px;
    padding-bottom: 10px;
    max-height: 170px;
    max-width: 570px;
    overflow: auto;
}

.filters-tab {
    background: var(--pale-grey);
    color: var(--dark-grey-6-e);
    padding: 8px 24px;
    margin: 0 3px;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    text-align: center;
    width: inherit;
}

.filters-tab__active {
    background: var(--pale-grey);
    color: var(--soft-blue);
}

.filters-tab__disabled {
    color: var(--light-grey-blue);
    cursor: not-allowed;;
}

.filters-control {
  background: var(--pale-grey);
  color: var(--dark-grey-6-e);
  padding: 4px 12px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  height: 34px;
}

.filter-filled-sign::before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  height: 8px;
  width: 8px;
  background-color: var(--scarlet-red);
  border-radius: 50%;
}

.txs-list-filter-wrapper {
  position: absolute;
  top: 65px;
  right: 0;
  background: var(--white);
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(110, 110, 110, 0.25);
  width: 300px;
  z-index: 100;
}

.source__action-block {
  position: fixed;
  bottom: 0;
  width: 640px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  padding: 12px 24px 24px;
  background: var(--white);
}

@media (max-width: 767px) {
  .filters-tab-wrapper {
    overflow: auto;
    padding-bottom: 8px;
  }
  .analytics-tabs {
    justify-content: unset;
  }
  .source__action-block {
    position: static;
    width: 100%;
  }
  .stat-item {
    width: 50%;
  }
  .risk-score-wrap {
    top: 130px;
  }
}
@media (max-width: 360px) {
  .stat-item {
    width: 100%;
  }
}
</style>
